<template>
    <div>
        <label for="hours">{{ this.$t('Horarios de Atención') }}</label><br/>
        <span>{{ this.$t('¿Qué horario tiene tu tienda?') }}</span>

        <vs-tabs alignment="fixed" class="mt-5" v-model="localSelectedTab">
            <vs-tab label="Horarios Fijos">
                <div class="tab-text">

                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div style="background: #fce8eb; color: #b6180f;" class="w-48 p-1 text-base">
                                {{ this.$t('Lunes a Viernes') }}
                            </div>
                        </div>
                        
                        <div class="vx-col w-1/2 mt-1">
                            <label for="MFinitHour">{{ this.$t('Apertura') }}</label>
                            <flat-pickr id="MFinitHour" :config="configdateTimePicker" v-model="localFixedHours.mf.hInit" class="w-full" />
                        </div>

                        <div class="vx-col w-1/2 mt-1">
                            <label for="MFendtHour">{{ this.$t('Cierre') }}</label>
                            <flat-pickr id="MFendtHour" :config="configdateTimePicker" v-model="localFixedHours.mf.hEnd" class="w-full" />
                        </div>
                    </div>

                    <div class="vx-row mt-5">
                        <div class="vx-col w-full">
                            <div style="background: #fce8eb; color: #b6180f;" class="w-64 p-1 text-base">
                                {{ this.$t('Sábados y Domingos') }}
                            </div>
                        </div>

                        <div class="vx-col w-1/2 mt-1">
                            <label for="SASUinitHour">{{ this.$t('Apertura') }}</label>
                            <flat-pickr id="SASUinitHour" :config="configdateTimePicker" v-model="localFixedHours.sasu.hInit" class="w-full" />
                        </div>

                        <div class="vx-col w-1/2 mt-1">
                            <label for="SASUendtHour">{{ this.$t('Cierre') }}</label>
                            <flat-pickr id="SASUendtHour" :config="configdateTimePicker" v-model="localFixedHours.sasu.hEnd" class="w-full" />
                        </div>
                    </div>
                </div>
            </vs-tab>
            
            <vs-tab label="Horarios Concretos">
                <div class="tab-text">
                    <div class="vx-row">
                        <div style="width: 40%;"></div>

                        <div style="width: 25%;">
                            <label>{{ this.$t('Apertura') }}</label>
                        </div>

                        <div style="width: 25%;">
                            <label>{{ this.$t('Cierre') }}</label>
                        </div>
                    </div>

                    <div v-for="(day, i) in localSpecificShedules" v-bind:key="i" class="vx-row">
                        <div class="p-1" style="width: 40%;">
                            <div style="background: #fce8eb; color: #b6180f;" class="p-1 font-normal text-base mt-1 text-center">
                                {{ $t(day.name) }}
                            </div>
                        </div>

                        <div class="p-1" style="width: 25%;">
                            <flat-pickr :id="`day${day.code}Init`" :config="configdateTimePicker" v-model="day.hInit" class="w-full" />
                        </div>

                        <div class="p-1" style="width: 25%">
                            <flat-pickr :id="`day${day.code}End`" :config="configdateTimePicker" v-model="day.hEnd" class="w-full" />
                        </div>

                        <div class="pt-3" style="width: 10%;">
                            <feather-icon icon="XCircleIcon" class="cursor-pointer" @click="deleteDay( i )" />
                        </div>
                    </div>
                </div>
            </vs-tab>
        </vs-tabs>

        <!-- festivos -->
        <div class="vx-row mt-5">
            <div class="vx-col w-full">
                <div style="background: #FFF; color: #b6180f;" class="w-64 p-1 font-bold text-lg cursor-pointer" @click="showHolidays = !showHolidays">
                    <i v-if="!showHolidays" width="20px" class="fa fa-plus inline-block align-middle mr-2" />
                    <img v-else :src="require(`@/assets/icons/PlusCircleBlueFilledIcon.svg`)" width="20px" class="inline-block align-middle mr-2" />
                    {{ this.$t('Festivos') }}
                </div>
            </div>

            <div v-if="showHolidays" class="vx-col w-1/2 mt-1">
                <label for="HinitHour">{{ this.$t('Apertura') }}</label>
                <flat-pickr id="HinitHour" :config="configdateTimePicker" v-model="localholidayHours.hInit" class="w-full" />
            </div>

            <div v-if="showHolidays" class="vx-col w-1/2 mt-1">
                <label for="HendtHour">{{ this.$t('Cierre') }}</label>
                <flat-pickr id="HendtHour" :config="configdateTimePicker" v-model="localholidayHours.hEnd" class="w-full" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default Vue.extend({
    props: {
        selectedTab      : { required: true, type: Number, },
        fixedHours       : { required: true },
        specificShedules : { required: true },
        holidayHours     : { required: true },
    },
    
    data() {
        return {
            showHolidays: true,
            configdateTimePicker: {
                enableTime: true,
                enableSeconds: true,
                noCalendar: true,
            },
        }
    },

    watch: {
        localFixedHours: {
            deep: true,
            handler() {
                this.validateFixedHours();
            }
        },

        localSpecificShedules: {
            deep: true,
            handler() {
                this.validateSpecificShedules();
            }
        },

        localholidayHours: {
            deep: true,
            handler() {
                this.validateHolidayHours();
            }
        },
        showHolidays( val ) {
            if( val == false ) this.localholidayHours = null;
            else this.localholidayHours = {
                hInit: '08:00:00',
                hEnd: '13:30:00',
            };
        }
    },

    methods: {
        validateFixedHours() {
            const mfHours   = this.localFixedHours.mf; // horarios de lunes a viernes L-V
            const sasuHours = this.localFixedHours.sasu; // horarios de sabados y domingos S-D

            const mfhInit = mfHours.hInit; // hora de inicio L-V
            const mfhEnd  = mfHours.hEnd; // hora fin L-V

            const sasuhInit = sasuHours.hInit; // hora de inicio S-D
            const sasuhEnd  = sasuHours.hEnd; // hora fin S-D

            const mfhInitInt = parseInt( mfhInit.replace(':', '') ); // hora inicio a entero L-V
            const mfhEndInt  = parseInt( mfhEnd.replace(':', '') ); // hora fin a entero L-V

            const sasuhInitInt = parseInt( sasuhInit.replace(':', '') ); // hora inicio a entero S-D
            const sasuhEndInt  = parseInt( sasuhEnd.replace(':', '') ); // hora fin a entero S-D

            if( (mfhInitInt > mfhEndInt) || (sasuhInitInt > sasuhEndInt)  ) {
                this.setNotification();
                this.$emit( 'hoursErr', true );
            } else {
                this.$emit( 'hoursErr', false );
            }
            
        },

        validateHolidayHours() {
            // si horarios festivos están escondidos
            if( !this.localholidayHours ){
                this.$emit( 'hoursErr', false );
                return;
            }

            const hInit = this.localholidayHours.hInit; // hora de inicio
            const hEnd  = this.localholidayHours.hEnd; // hora fin

            const hInitInt = parseInt( hInit.replace(':', '') ); // hora inicio a entero
            const hEndInt  = parseInt( hEnd.replace(':', '') ); // hora fin a entero

            if( hInitInt > hEndInt  ) {
                this.setNotification();
                this.$emit( 'hoursErr', true );
            } else {
                this.$emit( 'hoursErr', false );
            }
            
        },

        validateSpecificShedules() {
            this.localSpecificShedules.forEach( day => {
                const hInit = day.hInit; // hora de inicio
                const hEnd  = day.hEnd; // hora fin

                const hInitInt = parseInt( hInit.replace(':', '') ); // hora inicio a entero
                const hEndInt  = parseInt( hEnd.replace(':', '') ); // hora fin a entero

                if( hInitInt > hEndInt  ) {
                    this.setNotification();
                    this.$emit( 'hoursErr', true );
                } else {
                    this.$emit( 'hoursErr', false );
                }
            });
        },

        setNotification() {
            this.$vs.notify({
                title: this.$t('Error en la hora'),
                text: this.$t('La hora de apertura no puede ser mayor a la de cierre'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
                time: 7000,
            });
        },

        deleteDay( index ) {
            this.localSpecificShedules.splice( index, 1 );
        }
    },

    computed: {
        localSelectedTab: {
            get: function() {
                return this.selectedTab;
            },
            set: function( val ) {
                this.$emit( 'selectedTab', val );
            }
        },

        localFixedHours: {
            get: function() {                
                return this.fixedHours;
            },
            set: function( val ) {                
                this.$emit( 'fixedHours', val );
            }
        },

        localSpecificShedules: {
            get: function() {
                return this.specificShedules;
            },
            set: function( val ) {
                this.$emit( 'specificShedules', val );
            }
        },

        localholidayHours: {
            get: function() {
                return this.holidayHours;
            },
            set: function( val ) {
                this.$emit( 'holidayHours', val );
            }
        },
    },

    components: {
        flatPickr
    },
})
</script>